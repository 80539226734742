import React from "react";
import CallToAction from "components/Call-to-action/call-to-action";
import PagesHeader from "components/Pages-header";
import AboutIntro from "components/About-intro";
import MinimalArea from "components/Minimal-Area/minimal-area";


/* 

 teams images needs to be imported form another component*/


const About = () => {

  return (
    <>
      <PagesHeader />
      <AboutIntro />
      {/*   <MinimalArea /> */}
      <CallToAction />
    </>
  );
};

export const Head = () => {
  return (
    <>
      <title>About us</title>
    </>
  )
}

export default About;
